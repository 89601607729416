<template>
  <div :id="mapId" class="mapId"></div>
</template>
<script setup>
import mapboxgl from "mapbox-gl";
import mushroom from "cem-primary-api";
import { defineEmits, defineProps, onMounted, watch, ref } from "vue";
import MethodService from "../service/MethodService";
import { actionDashboard } from "../stores/actionDashboard";
const storeActionDashboard = actionDashboard();
const emit = defineEmits([""]);
const props = defineProps({
  markerGroup: Array,
  centerMap: Array,
  probe_column: Array,
  zoom: Number,
  flyMap: Number,
  dataForMapVN: Array,
  idProbe: String,
  firstTimeCenter: Boolean,
});

const mapId = ref("");
const center = [105.8440129, 21.0109351];
const arrayColumns = ref([]);
const showDataColumns = (data) => {
  let str = "";
  Object.entries(data).forEach(([key, value]) => {
    if (value?.length > 0) {
      str += `${MethodService.encodeHtml(key)}: ${MethodService.encodeHtml(
        value
      )}</br>`;
    } else
      str += `${MethodService.encodeHtml(
        key
      )}: <span class="none-info">Chưa có thông tin</span></br>`;
  });
  return str;
};
// style map có hoàng sa, trường sa
// const styleMap = {
//   version: 8,
//   sources: {
//     "raster-tiles": {
//       type: "raster",
//       tiles: [
//         "https://maps.vnpost.vn/api/tm/{z}/{x}/{y}.png?apikey=b232eb301471771069ab27b0715962f24a32055ad975464b",
//       ],
//       tileSize: 256,
//       attribution: "",
//     },
//   },
//   layers: [
//     {
//       id: "simple-tiles",
//       type: "raster",
//       source: "raster-tiles",
//       minzoom: 0,
//       maxzoom: 22,
//     },
//   ],
// };
mapId.value = "map_" + parseInt(Math.random() * 1e6);

const getColumns = async () => {
  const response = await mushroom.probe_column_config.listAsync();
  response.result.forEach((item) => {
    arrayColumns.value.push({
      col: item.code,
      title: item.title,
    });
  });
};

const loadImage = (map, imageUrl) => {
  return new Promise((resolve, reject) => {
    map.loadImage(imageUrl, (error, image) => {
      if (error) {
        reject(error);
        return;
      }
      resolve(image);
    });
  });
};
let map = null;
const popup = new mapboxgl.Popup({
  closeButton: true,
  closeOnClick: true,
});

const fn_initMap = (center) => {
  document.getElementById(mapId.value).innerHTML = "";
  mapboxgl.accessToken =
    "pk.eyJ1Ijoic2l0ZW5zYyIsImEiOiJjbDV2dHlja3MwZDdiM2lwOWtqaGJ4dTNnIn0.baIM03zTvYrPzEJZOlDtYw";

  map = new mapboxgl.Map({
    container: mapId.value,
    style: "mapbox://styles/mapbox/streets-v11",
    center: center,
    zoom: props.zoom ? props.zoom : 14,
    attributionControl: false,
  });

  // chức năng tìm kiếm
  // map.addControl(
  //   new mapboxgl.MapboxGeocoder({
  //     accessToken: mapboxgl.accessToken,
  //     mapboxgl: mapboxgl,
  //   })
  // );

  map.on("dragstart", async () => {
    const loc = map.getCenter();
    center[0] = loc.lng;
    center[1] = loc.lat;
    emit("moveMap", center);
  });
  map.on("load", async function () {
    map.resize();
    if (props.markerGroup) {
      const arrayDisabledProbe = []; // mảng các trạm không giám sát
      const arrayActiveProbe = []; // mảng các trạm đang giám sát
      const featuresActive = []; // các trường thông tin lên map các trạm đang giám sát
      const featuresDisabled = []; // các trường thông tin lên map các trạm không giám sát
      props.markerGroup.forEach((item) => {
        item.disabled
          ? arrayDisabledProbe.push(item)
          : arrayActiveProbe.push(item);
      });
      arrayActiveProbe.forEach((item) => {
        const valueProbe = props.dataForMapVN.find(x => x.probe_id === item.idProbe);
        featuresActive.push({
          type: "Feature",
          properties: {
            description: `
            <div class="card-header card-header-map">Thông tin trạm</div>
            <div class="card-body">
              <div>${showDataColumns(item.columns)}</div>
              <div class="btn-probe">
                <a href="${item.url}">
                  <button class="btn-probe__detail">
                    <i class="ri-send-plane-fill"></i>
                    <span>Xem chi tiết</span>
                  </button>
                </a>
              </div>
            </div>
          `,
            icon: "theatre",
            idMap: item.idProbe,
            name: valueProbe?.name,
            type: valueProbe?.type,
            centerLng: valueProbe?.center?.lng,
            centerLat: valueProbe?.center?.lat,
          },
          geometry: {
            type: "Point",
            coordinates: item.center,
          },
        });
      });
      arrayDisabledProbe.forEach((item) => {
        const valueProbe = props.dataForMapVN.find(x => x.probe_id === item.idProbe);
        featuresDisabled.push({
          type: "Feature",
          properties: {
            description: `
            <div class="card-header card-header-map">Thông tin trạm</div>
            <div class="card-body">
              <div>${showDataColumns(item.columns)}</div>
              <div class="btn-probe">
                <a href="${item.url}">
                  <button class="btn-probe__detail">
                    <i class="ri-send-plane-fill"></i>
                    <span>Xem chi tiết</span>
                  </button>
                </a>
              </div>
            </div>
          `,
            icon: "theatre",
            idMap: item.idProbe,
            name: valueProbe?.name,
            type: valueProbe?.type,
            centerLng: valueProbe?.center?.lng,
            centerLat: valueProbe?.center?.lat,
          },
          geometry: {
            type: "Point",
            coordinates: item.center,
          },
        });
      });
      // load ảnh trạm đang giám sát
      const markerActive = await loadImage(
        map,
        require("@/assets/images/marker.png")
      );
      // load ảnh trạm không giám sát
      const markerDeactive = await loadImage(
        map,
        require("@/assets/images/marker-off.png")
      );
      map.addImage("marker-on", markerActive);
      map.addImage("marker-off", markerDeactive);

      // hiển thị trạm không giám sát (khai báo trước thì hiển thị bên dưới)
      map.addSource("deactive", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: featuresDisabled,
        },
      });
      map.addLayer({
        id: "deactive",
        type: "symbol",
        source: "deactive",
        layout: {
          "icon-image": "marker-off",
          "icon-size": 0.35,
          "icon-allow-overlap": true, // true: hiển thị đầy đủ, false: sẽ bị mất marker khi zoom nhỏ(theo kích thước px marker)
        },
      });

      let clicking = false;

      // hiển thị trạm đang giám sát (khai báo sau thì hiển thị lên trên)
      map.addSource("active", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: featuresActive,
        },
      });
      map.addLayer({
        id: "active",
        type: "symbol",
        source: "active",
        layout: {
          "icon-image": "marker-on",
          "icon-size": 0.35,
          "icon-allow-overlap": true, // true: hiển thị đầy đủ, false: sẽ bị mất marker khi zoom nhỏ(theo kích thước px marker)
        },
      });

      const onClickMap = (e) => {
        emit("changeMapProbe", e.features[0].properties.idMap);
        if (clicking) return;
        clicking = true;
        setTimeout(() => {
          clicking = false;
        }, 100);
        const coordinates = e.features[0].geometry.coordinates;
        const description = e.features[0].properties.description;
        popup.setLngLat(coordinates)
          .setHTML(description)
          .addTo(map);
      };

      map.on("click", "active", onClickMap);

      map.on("click", "deactive", onClickMap);

      // Đổi hình trỏ chuột khi rê vào trạm
      map.on("mouseenter", "active", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "active", () => {
        map.getCanvas().style.cursor = "";
      });
      map.on("mouseenter", "deactive", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "deactive", () => {
        map.getCanvas().style.cursor = "";
      });
    }

    map.on("click", "maine", (e) => {
      emit("getIdMapInfo", e.features[0].properties.idMap);
    });
  });
  map.addControl(new mapboxgl.NavigationControl());
};

onMounted(() => {
  fn_initMap(props.centerMap ? props.centerMap : center);
  getColumns();
  // if(props.idProbe) {
  //   const presentProbe = props.markerGroup.find(item => item.idProbe === props.idProbe);
  //   popup.setLngLat(presentProbe?.center)
  //     .setHTML(`
  //           <div class="card-header card-header-map">Thông tin trạm</div>
  //           <div class="card-body">
  //             <div>${showDataColumns(presentProbe.columns)}</div>
  //             <div class="btn-probe">
  //               <a href="${presentProbe.url}">
  //                 <button class="btn-probe__detail">
  //                   <i class="ri-send-plane-fill"></i>
  //                   <span>Xem chi tiết</span>
  //                 </button>
  //               </a>
  //             </div>
  //           </div>
  //         `)
  //     .addTo(map);
  // }
});

watch(
  () => props.centerMap,
  (newCenter) => {
    if (!map) return;
    center[0] = newCenter[0];
    center[1] = newCenter[1];
    map.flyTo({
      center: newCenter,
      essential: true,
    });
  }
);
watch(
  () => storeActionDashboard.isRenderMap,
  (newData) => {
    console.log(newData);
    fn_initMap(props.centerMap);
  }
);

watch(
  () => props.flyMap,
  (newCenter) => {
    if (!map) return;
    center[0] = newCenter[0];
    center[1] = newCenter[1];
    map.flyTo({
      center: newCenter,
      essential: true,
      zoom: props.firstTimeCenter ? props.zoom : 7,
    });
    emit("setFirstTimeCenter");
  }
);

watch(
  () => props.idProbe,
  () => {
    const presentProbe = props.markerGroup.find(item => item.idProbe === props.idProbe);

    if (props.firstTimeCenter) return;
    popup.setLngLat(presentProbe?.center)
      .setHTML(`
            <div class="card-header card-header-map">Thông tin trạm</div>
            <div class="card-body">
              <div>${showDataColumns(presentProbe.columns)}</div>
              <div class="btn-probe">
                <a href="${presentProbe.url}">
                  <button class="btn-probe__detail">
                    <i class="ri-send-plane-fill"></i>
                    <span>Xem chi tiết</span>
                  </button>
                </a>
              </div>
            </div>
          `)
      .addTo(map);
  }
);
</script>

<style>
.mapId {
  position: relative;
  width: 100%;
  height: 500px;
}

.mapboxgl-canvas-container {
  height: 580px;
}

.title-probe {
  padding: 0 5px;
  margin-bottom: 5px;
}

.mapboxgl-popup-close-button,
.mapboxgl-popup-close-button:hover {
  background-color: rgba(var(--vz-danger-rgb));
  color: #fff;
  height: 35px;
  width: 35px;
}

.mapboxgl-popup-content {
  min-width: 180px;
  padding: 0;
}

.mapboxgl-popup-content .card-body {
  text-align: left;
}

.card-header-map {
  background-color: #151529;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 7px;
}

.btn-probe {
  text-align: center;
  width: 100%;
  margin-top: 10px;
}

.btn-probe__detail {
  background-color: #299cdb;
  color: #fff;
  width: fit-content;
  border-radius: 3px;
  border: none;
}

.btn-probe__detail i {
  position: relative;
  top: 1px;
}

.none-info {
  font-style: italic;
  color: #7c7c7c;
}
</style>
